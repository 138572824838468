.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btnButton {
  margin: auto;
  display: block;
  margin-top: 16px;
  background: #38369a;
  padding: 4px;
  border-radius: 4px;
  color: #fff;
  width: 160px;
}

.tick {
  display: inline-block;
  transform: rotate( 45deg);
  height: 13px;
  width: 7px;
  border-bottom: 3px solid rgb(56, 54, 154);
  border-right: 3px solid rgb(56, 54, 154);
  margin: 0px 9px;
}

.font14 {
  font-size: 15px;
  line-height: 30px;
}

.boxAnalytics {
  border: 1px solid rgb(177, 181, 185);
  padding: 14px;
  width: 360px;
  min-height: 500px;
  border-radius: 10px;
  position:relative;
}

.inputFormModal {
  border: 1px solid gray;
  padding: 4px;
  border-radius: 4px;
  width: 100%;
}

.floatRight {
  float: right;
}

.formInline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.formInline input {
  width: 46%;
  margin: 2%;
  padding: 10px;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ddd;
}

.formInline textarea {
  width: 100%;
  margin: 2%;
  padding: 10px;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ddd!important;
}

.centerImages {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.centerImages img {
  margin: 30px;
}

.imagesOne {
  height: 150px;
}

.imagesTwo {
  height: 200px;
}

.imagesFour {
  margin: auto;
  margin-top: 25px;
}

.boxAnalytics2 {
  padding: 14px;
  width: 311px;
  height: 220;
  max-height: 220px;
}

.about {
  padding: 14px;
  width: 400px;
  height: 300;
  max-height: 300;
  min-height: 300px;
  overflow-y: hidden;
}

.makeEnquery {
  border: 1px solid #38369A;
  width: 150px;
  text-align: center;
  margin: auto;
  margin-bottom: 18px;
  margin-top: 16px;
}

.bigName {
  font-size: 30px;
}

.selected {
  font-weight: bold;
}

.ReactModal__Overlay--after-open {
  background: rgb(0 0 0 / 51%)!important;
  z-index: 9999
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.hr {
  margin-top: -30px;
}

.service-panel {
  border: 1px solid #E8F2FA;
}

.service-panel:hover {
  border: 1px solid #3B3A97;
}

.giff {
  margin: auto;
  display: block;
  width: 80%;
}

.contactBtn {
  position: relative;
  left: -12px;
}

.whiteBg {
  background: #fff;
  color: #38369a;
  font-weight: bold;
}

.mgTB10 {
  margin-top: 10px!important;
  margin-bottom: 10px!important;
}

.imgRes {
  width: "431px";
  height: "282px"
}

.marginSlider {
  background-color: rgb(255, 255, 255);
  padding-top: 30px;
  padding-bottom: 40px;
}

.bussinessImg {
  height: 15rem;
  flex-grow: 1
}

.minHeightBussiness {
  width: 511px;
  height: 507px;
}

.showMobile {
  display: none;
}
.showInMobile{
  display: none;
}
.showInDesktop{
  display: block;
}
@media screen and (min-width: 800px) and (max-width: 1280px) {}

@media (min-width: 1920px) and (max-width: 2560px) {
  .statisticImg{
    width: 85px; 
    height: auto;
  }
  .kickstart-float{
    position: absolute;
    right: 2rem; 
    top: 2rem; 
  }
  .businessHeading{
    font-size: 2rem
  }
  .our-offerings-text{
    font-size: 4rem;
  }
  .our-offerings-sub-text {
    font-size: 2rem;
    line-height: 3rem;
  }
  .service-text{
    font-size: 3.5rem;
  }

  .functional-area-items{
    font-size:2.5rem
  }
  .mobileFunctionalAreaHeading{
    font-size:3.5rem
  }
  .big-text, .market-expert-text, .specialised-text{
    font-size: 3.5rem;
  }
  .text-xl {
    font-size: 2.25rem;
    line-height: 3.75rem;
  }
  .work-para {
    font-size: 2rem;
    line-height: 3rem;
  }
  .boxAnalytics {
    min-height: 546px;
  }
  .justify-between {
    justify-content: space-around;
  }
  .textinxl{
    font-size:1.6rem;
    line-height:2.2rem;
  }
  .offeringxltext{
    font-size:1.5rem
  }
  .cardHeading{
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .customisepara{
    font-size:1.5rem
  }
  .heading1920{
    font-size: 3.25rem
  }
  .subheading1920{
    font-size: 1.8rem;
    padding:15 0;
  }
  .aboutstory1920 {
    font-size:1.8rem;
  }
  .aboutstorypara1920 {
    font-size:1.8rem;
    line-height:3rem;
  }
  .aboutstoryparatwo1920{
    margin-top:1rem
  }
  .aboutstoryparathree1920{
    margin-bottom:1rem
  }
  .functionalareasdesc{
    font-size:2rem;
    line-height: 2.75rem;
  }
  .functionalareasdesc-productoffering{
    font-size:1.8rem;
    line-height: 2.75rem;
  }
  .marketplace-heading{
    font-size:2rem
  }
  .marketplace-subheading{
    font-size:1.6rem
  }
  .marketplace-card-title {
    font-size:1.5rem
  }
  .service-text{
    font-size: 1.5rem;
    line-height: 2rem; 
  }
  .mobileServiceData{
    background-color: #E8F2FA;
  }
}

@media only screen and (max-width:480px) {
  .tab-about-us-1{
    display: none;
  }
  .tab-about-us-2{
    display: block;
  }
  .plan-text{
    font-size: 1.3rem;
  }
  .boxAnalytics {
    border: 1px solid rgb(177, 181, 185);
    padding: 14px;
    width: auto!important;
    min-height: 550px;
    border-radius: 10px;
    position:relative;
    margin:0 20px; 
  }
  .kickstart-popup-button{
    left: 7.3rem!important;
  }
}

@media only screen and (min-width:768px) {
  .bussinessImg {
    height: 10rem;
    flex-grow: 1
  }
  .tab-about-us-1{
    display: block;
  }
  .tab-about-us-2{
    display: none;
  }
  .service-image{
    width:100px;
    height:110px;
  }
  .service-heading{
    font-size:1.5rem;
  }
  .market-expert-text{
    font-size: 1.5rem;
  }
}

@media (min-width:768px) and (max-width:1020px) {
  .service-image{
    width:60px;
    height:70px;
  }
  .service-heading{
    font-size:1.5rem;
  }
  .kickstart-popup-button{
    left: 5.5rem!important;
  }
}

@media (min-width:400px) and (max-width:600px) {
  .kickstart-popup-button{
    left: 7.5rem!important;
  }
}

@media only screen and (max-width:640px) {

  .kickstart-popup-button{
    left: 4.5rem!important;
  }
  .plan-text{
    font-size: 1.3rem;
  }
  .tab-about-us-1{
    display: none;
  }
  .tab-about-us-2{
    display: block;
  }
  .market-expert-text{
    font-size:1.25rem
  }
  .borderhover{
    display: none;
  }
  .service-text{
    font-size:1.5rem
  }
  .imagesFour {
    margin: auto;
    margin-top: 0;
    width:50px
  }

  .tick{
    position: absolute;
    margin-left: -20px;
    margin-top: 5px;
  }
  .financeCardDesc{
    padding-left: 25px;
  }
  .statisticImg{
    width: 50px!important; 
    height: auto;
  }

  .boxAnalytics {
    border: 1px solid rgb(177, 181, 185);
    padding: 14px;
    width: 70%;
    min-height: 550px;
    border-radius: 10px;
    position:relative;
    margin:0 20px; 
  }

  .textinxl{
    font-size:0.7rem;
    line-height:1rem;
  }
  .businessBanner{
    background-color: "#F0EFF7"; 
    height:150px;
  }
  .businessHeading{
    font-size: 1.5rem
  }
  .businessCardOne{
    margin-top: -2rem;
    margin-bottom: 3rem;
  }
  .businessCardThree{
    margin-top: 3rem;
  }
  .px-8 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .py-16 {
    padding-top: 4px;
    padding-bottom: 4px
  }
  .p-4 {
    padding: 4px;
  }
  .py-8 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .marginSlider {
    background-color: rgb(255, 255, 255);
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .mr-16 {
    margin-right: 4px
  }
  .pr-4 {
    padding-right: 4px;
  }
  .text-3xl {
    font-size: 1.5rem
  }
  .ml-32 {
    margin-left: 4px
  }
  .mr-32 {
    margin-right: 4px
  }
  .bussinessImg {
    height: 150px;
    flex-grow: 1;
    padding: 12px;
  }
  .bussinessImgMobile {
    height: 4rem;
    margin-right:20px;
  }
  .minHeightBussiness {
    height: 480px;
  }
  .p-5 {
    padding: 0px;
  }
  .mobile {
    display: block!important;
  }
  .mr-20 {
    margin-right: 0px;
  }
  .ml-20 {
    margin-left: 0px;
  }
  .w-44 {
    width: 100%;
  }
  .showMobile {
    display: block;
    margin-top: 25px;
  }
  .showInMobile{
    display: block;
  }
  .showInDesktop{
    display: none;
  }
  .mobileFunctionalAreaHeading{
    font-size:1.4rem
  }
  .mt25{
    margin-top:25px;
  }
  .mr-f-20 {
    margin-right: 2px;
    margin-top: 20px;
  }
  .w60 {
    width: 90%;
  }
  .w-72 {
    width: 100%;
  }
  .mt-8 {
    margin-top: 1rem;
  }
  .mobileFunctionalArea{
    font-size: 0.7rem;
    line-height: 1rem;
    text-align: left;
    margin-top: 1rem;
  }
  .mobileFunctionalAreaTitle{
    text-align: left;
    margin-bottom: -12px;
  }
  .mobileFunctionalAreaButton{
    font-size: 0.6rem;
    padding: 0.2rem 1rem;
    /* padding-left: 1rem; */
  }
  .mobileFunctionalAreaButtonWrapper{
    width: 6rem;
    padding: 0rem 0.25rem 0.25rem 0.25rem;
    float:right;
  }
  .marketExpertSubscribeButton{
    font-size: 0.6rem;
    padding: 0.2rem 1rem 0.2rem 1.5rem
  }
  .marketExpertSubscribe{
    width: 6rem;
    padding: 0rem 0.25rem 0.25rem 0.25rem;
  }
  .minHeightBussiness {
    width: 115px;
    height: 145px;
  }
  .service-panel {
    border: 1px solid #E8F2FA;
    padding:5px
  }
  .alignServiceItem {
    transform: translateY(30%);
  }
  .serviceHeading{
    font-size: 0.6rem;
  }
  .contactBtn{
    width: 20%!important;
    margin-left: 15px;
  }
  .relativeContact{
    font-size: 0.7rem;
  }
  .bottom_border {
    margin-top: 2rem;
    border-top: 0.6px solid #B2AFD7;
  }
}

/* Extra Small Devices, Phones */

@media only screen and (min-width: 480px) {
  .statisticImg{
    width: 50px; 
    height: auto;
  }
  
}

@media (min-width: 1280px) and (max-width: 2560px) {
  .statisticImg{
    width: 75px; 
    height: auto;
  }
}
@media (min-width: 360px) and (max-width: 480px) {
  .statisticImg{
    width: 40px!important; 
    height: auto;
  }
}
/* service card */

.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card:hover .flip-card-front {
  display:none;
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.businessBanner{
  background-color: "#F0EFF7"; 
  height: 440px;
}

.tick{
  position: absolute;
  margin-left: -20px;
  margin-top: 5px;
}
.financeCardDesc{
  padding-left: 25px;
}

.activeCard{
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

.edit-profile-button{
  background-color:#F74300
}
a:hover{
  text-decoration: none;
  color:none
}

.nav-item a:hover{
  text-decoration: none;
  color:#fff;
  text-shadow: 0px 0px 10px #2d3436;
}